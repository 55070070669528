import React, { lazy, useEffect, useContext } from 'react';
import {
    ShoppingCartOutlined,
    ReadOutlined,
    UserOutlined,
    EyeOutlined,
    HomeOutlined,
    SendOutlined,
    TeamOutlined,
    PhoneOutlined,
    SettingOutlined,
    WarningOutlined,
    LogoutOutlined,
    FileTextOutlined,
    ShoppingOutlined,
    EuroOutlined,
    MoreOutlined
} from '@ant-design/icons';
import {
    useLocation,
    useHistory,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';

import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

import QuickbooksService from 'services/QuickbooksService';

import DesktopLayout, {MenuItem} from 'shared/components/layout/DesktopLayout';
import DeliveryList from './delivery/DeliveryList';
import DeliveryDay from './delivery/DeliveryDay';
import ActiveDeliveryZone from './delivery/DeliveryZone/ActiveDeliveryZone';
import SeniorDeliveryZone from './delivery/DeliveryZone/SeniorDeliveryZone';

import { AuthContext } from 'shared/contexts/AuthContext';
import LoaderOverlay from 'shared/components/LoaderOverlay';
import ChangePasswordModal from 'shared/views/login/ChangePasswordModal';

import usePermissions, { ROLES } from 'shared/hooks/usePermissions';
import Invoices from "./invoices/invoices";
import Factures from "./invoices/factures";

const Home = lazy(() => import('views/Home'));
const EmployeeList = lazy(() => import('views/employee/EmployeeList'));
const ClientList = lazy(() => import('views/client/ClientList'));
const EditClient = lazy(() => import('views/client/EditClient'));
const TutorList = lazy(() => import('views/tutor/TutorList'));
const EditTutor = lazy(() => import('views/tutor/EditTutor'));
const ImportInvoicesApa = lazy(() => import('views/import/ImportInvoicesApa'));
const Menus = lazy(() => import('views/menu/Menus'));
const CommandList = lazy(() => import('views/command/CommandList'));
const CommandScreen = lazy(() => import('shared/components/CommandScreen'));
const ConfigurationScreen = lazy(
    () => import('views/configuration/ConfigurationScreen'),
);
const CallReportList = lazy(() => import('views/callReport/CallReportList'));
const Kitchen = lazy(() => import('shared/views/kitchen/Kitchen'));
const Control = lazy(() => import('shared/views/control/Kitchen'));
const SeniorCommands = lazy(() => import('shared/views/seniorCommand/SeniorCommands'));

const QUICKBOOKS = gql`
    {
        quickbooks {
            id
            refreshTokenExpiresAt
        }
    }
`;

export default function AppLayout() {
    const { data, refetch } = useQuery(QUICKBOOKS);
    const { updateAuthToken } = useContext(AuthContext);
    const { isGranted, loading: permissionsLoading } = usePermissions();
    const quickbooks: Quickbook[] = data ? data.quickbooks : [];
    const daysCountBeforeExpiration = QuickbooksService.getDaysCountBeforeExpiration(
        quickbooks,
    );

    useEffect(() => {
        const intervalId = setInterval(() => refetch(), 60000);
        return () => {
            clearInterval(intervalId);
        };
    }, [refetch]);

    const { pathname } = useLocation();
    const history = useHistory();

    if (permissionsLoading) {
        return <LoaderOverlay loading />;
    }

    const menuItems: MenuItem[] = [
        isGranted(ROLES.MANAGER) && {
            route: '/',
            icon: <HomeOutlined />,
            label: 'Accueil',
        },
        isGranted(ROLES.MANAGER) && {
            route: '/equipe',
            icon: <TeamOutlined />,
            label: 'Equipe',
        },
        isGranted(ROLES.MANAGER) && {
            route: '/clients',
            icon: <UserOutlined />,
            label: 'Clients',
        },
        isGranted(ROLES.MANAGER) && {
            route: '/tuteurs',
            icon: <EyeOutlined />,
            label: 'Tuteurs',
        },
        isGranted(ROLES.MANAGER) && {
            route: '/menus',
            icon: <ReadOutlined />,
            label: 'Menus',
        },
        (isGranted(ROLES.MANAGER) || isGranted(ROLES.DELIVERY_MAN)) && {
            route: '/saisie-seniors',
            icon: <FileTextOutlined />,
            label: 'Saisie seniors',
        },
        (isGranted(ROLES.MANAGER) ||
             isGranted(ROLES.COOK)) && {
            route: '/cuisine',
            icon: <ShoppingOutlined />,
            label: 'Cuisine',
        },
        (isGranted(ROLES.MANAGER) ||
            isGranted(ROLES.DELIVERY_MAN)) && {
            route: '/control',
            icon: <ShoppingOutlined />,
            label: 'Controles livraisons',
        },
        isGranted(ROLES.MANAGER) && {
            route: '/livraisons',
            icon: <SendOutlined />,
            label: 'Livraisons',
        },
        isGranted(ROLES.MANAGER) && {
            route: '/invoices',
            icon: <EuroOutlined />,
            label: 'Facturation',
        },
        isGranted(ROLES.MANAGER) && {
            route: '/appels',
            icon: <PhoneOutlined />,
            label: 'Suivi des appels',
        },
        isGranted(ROLES.MANAGER) && {
            route: '/import',
            icon: <FileTextOutlined />,
            label: 'Imports',
        },
        {
            route: 'logout',
            icon: <LogoutOutlined />,
            label: 'Déconnexion',
        },
    ].filter((m) => !!m) as MenuItem[];
    const selectedMenu: any = pathname;
    function handleMenuClick(route: string) {
        if (route === 'logout') {
            updateAuthToken(null);
        } else if (route !== pathname) {
            history.push(route);
        }
    }

    return (
        <DesktopLayout
            menuItems={menuItems}
            selectedMenu={selectedMenu}
            onMenuClick={handleMenuClick}
        >
            <ChangePasswordModal />
            <Switch>
                {isGranted(ROLES.MANAGER) && (
                    <Route exact path="/" component={Home} />
                )}
                {isGranted(ROLES.MANAGER) && (
                    <Route exact path="/equipe" component={EmployeeList} />
                )}
                {isGranted(ROLES.MANAGER) && (
                    <Route exact path="/clients" component={ClientList} />
                )}
                {isGranted(ROLES.MANAGER) && (
                    <Route
                        exact
                        path="/clients/:clientId"
                        component={EditClient}
                    />
                )}
                {isGranted(ROLES.MANAGER) && (
                    <Route exact path="/tuteurs" component={TutorList} />
                )}
                {isGranted(ROLES.MANAGER) && (
                    <Route
                        exact
                        path="/tuteurs/:tutorId"
                        component={EditTutor}
                    />
                )}
                {isGranted(ROLES.MANAGER) && (
                    <Route exact path="/menus" component={Menus} />
                )}
                {isGranted(ROLES.MANAGER) && (
                    <Route exact path="/livraisons" component={DeliveryList} />
                )}
                {isGranted(ROLES.MANAGER) && (
                    <Route
                        exact
                        path="/livraisons/zones/actif"
                        component={ActiveDeliveryZone}
                    />
                )}
                {isGranted(ROLES.MANAGER) && (
                    <Route
                        exact
                        path="/livraisons/zones/senior"
                        component={SeniorDeliveryZone}
                    />
                )}
                {isGranted(ROLES.MANAGER) && (
                    <Route
                        exact
                        path="/livraisons/:date/:clientType"
                        component={DeliveryDay}
                    />
                )}
                {isGranted(ROLES.MANAGER) && (
                    <Route exact path="/appels" component={CallReportList} />
                )}
                {isGranted(ROLES.MANAGER) && (
                    <Route exact path="/invoices" component={Invoices} />
                )}
                {(isGranted(ROLES.MANAGER) ||
                    isGranted(ROLES.DELIVERY_MAN)) && (
                    <Route
                        exact
                        path="/saisie-seniors"
                        component={SeniorCommands}
                    />
                )}
                {(isGranted(ROLES.MANAGER) ||
                    isGranted(ROLES.COOK)) && (
                    <Route exact path="/cuisine" component={Kitchen} />
                )}

                {(isGranted(ROLES.MANAGER) ||
                    isGranted(ROLES.DELIVERY_MAN)) && (
                    <Route exact path="/control" component={Control} />
                )}

                {isGranted(ROLES.DELIVERY_MAN) && !isGranted(ROLES.MANAGER) && (
                    <Route exact path="/">
                        <Redirect to="/saisie-seniors" />
                    </Route>
                )}
                {isGranted(ROLES.COOK) && !isGranted(ROLES.DELIVERY_MAN) && !isGranted(ROLES.MANAGER) && (
                    <Route exact path="/">
                        <Redirect to="/cuisine" />
                    </Route>
                )}
                {isGranted(ROLES.MANAGER) && (
                    <Route exact path="/import" component={ImportInvoicesApa}/> 
                )}
            </Switch>
        </DesktopLayout>
    );
}
