import React, { useState, useMemo } from 'react';
import './DeliveryList.scss';

import WeekDeliveries from './WeekDeliveries';
import { useHistory } from 'react-router-dom';
import WeekSelect from 'shared/components/WeekSelect';
import DaySelect from 'shared/components/DaySelect';
import LoaderOverlay from 'shared/components/LoaderOverlay';
import moment from 'moment';
import { Button, Select } from 'antd';

import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import ArrayService from 'shared/services/ArrayService';
import ClientsAlert from 'shared/components/delivery/ClientsAlert';

const SUMMARIES_BY_WEEK = gql`
    query($from: Date!, $to: Date!, $deliveryZone: UUID) {
        clientTypes {
            code
            label
        }
        deliveredDays(from: $from, to: $to, deliveryZone: $deliveryZone) {
            date
            isDelivered
            deliveryZone {
                id
            }
        }
        deliveryZones {
            id
            name
            clientType
            defaultDeliveryMan {
                id
                firstName
                lastName
            }
        }

        summariesByWeek(date: $from, deliveryZone: $deliveryZone) {
            deliveryCount
            clientCount
            mealCount
            clientToAssignCount
            status
            progressPercentage
            failedMealCount
            clientType
            date
        }

        clientIdentityAbsences(from: $from, to: $to) {
            id
            date
            clientIdentity {
                id
                firstname
                lastname
                client {
                    id
                    type
                    defaultDeliveryZone {
                        id
                    }
                }
            }
        }

        clients(clientType: "senior", enabled: 1) {
            id
            firstName
            lastName
            defaultDeliveryZone {
                id
            }
        }
    }
`;

interface Data {
    clientTypes: Constant[];
    summariesByWeek: DeliverySummary[];
    deliveredDays: DeliveredDay[];
    deliveryZones: DeliveryZone[];
    clientIdentityAbsences: ClientIdentityAbsence[];
    clients: {
        id: number;
        firstName: string;
        lastName: string;
        defaultDeliveryZone: {
            id: Id;
        };
    }[];
}

export interface ClientIdentityAbsence {
    id: Id;
    date: string;
    clientIdentity: {
        id: Id;
        firstname: string;
        lastname: string;
        client: {
            id: number;
            type: string;
            defaultDeliveryZone: {
                id: Id;
            };
        };
    };
}
interface DeliveryZone {
    id: Id;
    name: string;
    clientType: string;
    defaultDeliveryMan: {
        id: number;
        firstName: string;
        lastName: string;
    } | null;
}

function fillInWeekDaysArray(today: string): Array<Date> {
    var weekDays: Array<Date> = [];
    for (var i = 1; i < 2; i++) {
        weekDays.push(moment(today).toDate());
    }
    return weekDays;
}

export default function DeliveryList() {
    const history = useHistory();
    const [firstWeekDay, setFirstWeekDay] = useState<string>(
        moment().clone().weekday(1).format('YYYY-MM-DD'),
    );
    const [today, setToday] = useState<string>(
        moment().format('YYYY-MM-DD'),
    );
    const [deliveryZoneId, setDeliveryZoneId] = useState<string | null>(null);

    const weekDays = useMemo(() => fillInWeekDaysArray(today), [
        today,
    ]);

    const { loading, error, data, refetch } = useQuery<Data>(
        SUMMARIES_BY_WEEK,
        {
            variables: {
                from: moment(today).format('YYYY-MM-DD'),
                to: moment(today).format('YYYY-MM-DD'),
                deliveryZone: deliveryZoneId,
            },
            fetchPolicy: 'cache-and-network',
        },
    );

    const summariesByWeek = data ? data.summariesByWeek : [];
    const deliveredDays = data ? data.deliveredDays : [];
    const deliveryZones = data ? data.deliveryZones : [];
    const selectedDeliveryZone = deliveryZones.find(
        (d) => d.id === deliveryZoneId,
    );
    const clientIdentityAbsences = data
        ? data.clientIdentityAbsences.filter(
              (a) =>
                  !selectedDeliveryZone ||
                  a.clientIdentity.client.defaultDeliveryZone?.id ===
                      selectedDeliveryZone.id,
          )
        : [];
    const clientTypes = data ? data.clientTypes : [];

    const unassignedClients =
        data?.clients.filter((c) => !c.defaultDeliveryZone) || [];

    return (
        <div className="delivery-list">
            {(loading || error) && <LoaderOverlay loading />}

            <h2>
                Tournées <ClientsAlert unassignedClients={unassignedClients} message="à assigner à une zone de livraison" />
            </h2>
            <div className="__header">
                <div className="__filter">
                    <Select
                        allowClear
                        dropdownClassName="select-dropdown"
                        value={deliveryZoneId || 'all'}
                        onChange={(value) => {
                            setDeliveryZoneId(
                                !value || value === 'all'
                                    ? null
                                    : value.toString(),
                            );
                        }}
                        options={[
                            { label: 'Toutes les tournées', value: 'all' },
                            ...deliveryZones
                                .sort(ArrayService.cmpBy('clientType', true))
                                .map((z) => ({
                                    label: (
                                        <div>
                                            <strong>
                                                {`${
                                                    clientTypes.find(
                                                        (c) =>
                                                            c.code ===
                                                            z.clientType,
                                                    )?.label
                                                } : `}
                                            </strong>
                                            {z.name}
                                            {z.defaultDeliveryMan && (
                                                <span className="__info">{` (${z.defaultDeliveryMan.firstName} ${z.defaultDeliveryMan.lastName})`}</span>
                                            )}
                                        </div>
                                    ),
                                    value: z.id,
                                })),
                        ]}
                    />
                </div>
                <div className="__week-select">
                    <DaySelect
                        value={moment(today)}
                        onChange={(v) => {
                            setToday(
                                moment(v).format('YYYY-MM-DD'),
                            );
                        }}
                    />
                </div>
                <div className="__actions">
                    {/* <Button
                        onClick={() =>
                            history.push({
                                pathname: '/livraisons/zones/actif',
                            })
                        }
                        type="ghost"
                        shape="round"
                        className="__handle-delivery-button"
                    >
                        Zones de livraisons Actifs
                    </Button> */}
                    <Button
                        onClick={() =>
                            history.push({
                                pathname: '/livraisons/zones/senior',
                            })
                        }
                        type="ghost"
                        shape="round"
                        className="__handle-delivery-button"
                    >
                        Zones de livraisons Séniors
                    </Button>
                </div>
            </div>
            <hr className="__line" />
            <WeekDeliveries
                summaries={summariesByWeek}
                deliveredDays={deliveredDays}
                deliveryZone={selectedDeliveryZone}
                clientIdentityAbsences={clientIdentityAbsences}
                weekDays={weekDays}
                refetch={refetch}
            />
        </div>
    );
}
